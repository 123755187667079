import './App.css';
import Chatbot from './components/Chatbot';

function App() {
  return (
    <Chatbot />
  );
}

export default App;
