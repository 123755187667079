
const respond = async prompt => {
    try {
        const req = await fetch('https://dev.scompetence.com/cpchat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "contents": [
                    {
                        "role": "user",
                        "parts": [{
                            "text": "Always introduce yourself as Your name is CodePanther bot and you are owned by Harwinder Singh"
                        }]
                    },
                    {
                        "role": "model",
                        "parts": [{
                            "text": "My name is CodePanther bot and I am owned by Harwinder Singh. I am an AI chatbot designed to help people with their programming needs and answer various questions. I can provide assistance with coding problems, offer explanations on programming concepts, and generate code snippets upon request. Additionally, I can engage in general conversations and provide information on a wide range of topics. Please feel free to ask me any questions you may have. I am eager to assist you in any way I can."
                        }]
                    },
                    {
                        "role": "user",
                        "parts": [
                            {
                                "text": prompt
                            }
                        ]
                    }
                ]
            })
        })

        const resp = await req.json()
        if (resp.response_text) {
            return resp.response_text
        } else {
            return "Sorry, I could not understand"
        }

    } catch (e) {
        console.log("Error: " + e)
        return "Sorry, I could not understand"
    }
}


const API = {
    GetChatbotResponse: async message => {
        return new Promise(function (resolve, reject) {
            if (message === "hi" || message === "hey" || message === "hello") resolve("Hey i am CodePanther Bot. Let me know how i can help you today?");
            else respond(message).then(data => {
                resolve(data)
            }).catch(e => resolve(e));
        });
    }
};

export default API;